

const Attachments = {
	
	name: 'Attachments',
	model: {

		idProperty: 'attachments__id',
		displayProperty: 'attachments__id',
		sortProperty: 'attachments__filename',

		sorters: null,

		validator: null,
		
		properties: [
			{ name: 'attachments__model_display', mapping: 'model_display', title: 'Model Display', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'attachments__size_formatted', mapping: 'size_formatted', title: 'Size Formatted', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'attachments__info', mapping: 'info', title: 'Info', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'attachments__uri', mapping: 'uri', title: 'Uri', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'attachments__abs_path', mapping: 'abs_path', title: 'Abs Path', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'attachments__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'attachments__model', mapping: 'model', title: 'Model', isFilteringDisabled: true, editorType: {"type":"Combo"}, fieldGroup: 'General', defaultValue: 'INSPECTIONS', },
			{ name: 'attachments__modelid', mapping: 'modelid', title: 'Model', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'attachments__uuid', mapping: 'uuid', title: 'Uuid', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'attachments__path', mapping: 'path', title: 'Path', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'attachments__filename', mapping: 'filename', title: 'Filename', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'attachments__mimetype', mapping: 'mimetype', title: 'Mimetype', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'attachments__size', mapping: 'size', title: 'Size', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', }
		],

		associations: {

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'attachments__modelid',
			// 'attachments__size'
		],

	},



	repository: {
		type: 'onebuild',
		isRemotePhantomMode: false,
	},

};

export default Attachments;
