/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import GroupsUsersGridColumns from './Columns/GroupsUsersGridColumns.js';

export default function GroupsUsersGrid(props) {
	return <Grid
				reference="GroupsUsersGrid"
				model="GroupsUsers"
				usePermissions={true}
				columnsConfig={GroupsUsersGridColumns}

				{...props}
			/>;
}