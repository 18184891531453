/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import GroupsUsersEditorWindow from '../../Window/GroupsUsersEditorWindow.js';
import GroupsUsersGridColumns from '../../Grid/Columns/GroupsUsersGridColumns.js';

export default function GroupsUsersGridEditorPanel(props) {
	return <GridPanel
				reference="GroupsUsersGridEditorPanel"
				model="GroupsUsers"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={GroupsUsersEditorWindow}
				columnsConfig={GroupsUsersGridColumns}
				
				
				{...props}
			/>;
}