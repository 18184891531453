/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import PermissionsEditorWindow from '../../Window/PermissionsEditorWindow.js';
import PermissionsGridColumns from '../../Grid/Columns/PermissionsGridColumns.js';

export default function PermissionsGridEditorPanel(props) {
	return <GridPanel
				reference="PermissionsGridEditorPanel"
				model="Permissions"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={PermissionsEditorWindow}
				columnsConfig={PermissionsGridColumns}
				
				
				{...props}
			/>;
}