/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GroupsUsersGridPanel from './GroupsUsersGrid.js';

export default function GroupsUsersFilteredGridPanel(props) {
	return <GroupsUsersGridPanel
				reference="GroupsUsersFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}