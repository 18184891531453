/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import UsersSideGridEditor from './UsersSideGridEditor.js';

export default function UsersFilteredSideGridEditor(props) {
	return <UsersSideGridEditor
				reference="UsersFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}