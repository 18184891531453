/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import GroupsUsersEditorWindow from '../../../Window/GroupsUsersEditorWindow.js';

function GroupsUsersComboEditor(props) {
	return <ComboEditor
				reference="GroupsUsersComboEditor"
				model="GroupsUsers"
				uniqueRepository={true}
				Editor={GroupsUsersEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default GroupsUsersComboEditor;