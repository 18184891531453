/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import GroupsGridColumns from '../../Grid/Columns/GroupsGridColumns.js';
import {
	EDITOR_TYPE__INLINE,
} from '@onehat/ui/src/Constants/Editor.js';

export default function GroupsInlineGridEditorPanel(props) {
	return <GridPanel
				reference="GroupsInlineGridEditorPanel"
				model="Groups"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__INLINE}
				columnsConfig={GroupsGridColumns}
				
				
				{...props}
			/>;
}