/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import UsersGridPanel from './UsersGrid.js';

export default function UsersFilteredGridPanel(props) {
	return <UsersGridPanel
				reference="UsersFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}