/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PermissionsGridPanel from './PermissionsGrid.js';

export default function PermissionsFilteredGridPanel(props) {
	return <PermissionsGridPanel
				reference="PermissionsFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}