import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Secure = {
	
	name: 'Secure',
	model: {

		idProperty: 'key',
		displayProperty: 'value',

		validator: yup.object().shape({
			key: yup.string()
					.nullable()
					.required(),
			value: yup.string()
					.nullable()
					.required(),
		}),

		properties: [
			{ name: 'key', },
			{ name: 'value', },
			{ name: 'isJson', type: 'bool', default: false, },
			{ name: 'isOneBuild', type: 'bool', default: false, },
			{ name: 'model', },
		],
		
	},

	repository: {
		type: 'secureLocal',
		isPaginated: false,
		isAutoLoad: true,
	},

};

export default Secure;
