import { 
	createNavigationContainerRef,
	StackActions,
} from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef()


export function logout() {
	// popToTop();
	replace('Login');
}

export function navigate(name, params) {
	if (navigationRef.isReady()) {
		navigationRef.navigate(name, params);
	}
}

export function goBack() {
	if (navigationRef.isReady()) {
		navigationRef.goBack();
	}
}

export function push(...args) {
	if (navigationRef.isReady()) {
		navigationRef.dispatch(StackActions.push(...args));
	}
}

export function replace(route) {
	if (navigationRef.isReady()) {
		navigationRef.dispatch(StackActions.replace(route));
	}
}

export function popToTop() {
	if (navigationRef.isReady() && navigationRef.canGoBack()) {
		navigationRef.dispatch(StackActions.popToTop());
	}
}

export function getRef() {
	return navigationRef;
}
