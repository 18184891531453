/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import GroupsEditor from '../Editor/GroupsEditor.js';
import GroupsGridColumns from './Columns/GroupsGridColumns.js';

export default function GroupsSideGridEditor(props) {
	return <SideGridEditor
				reference="GroupsSideGridEditor"
				model="Groups"
				usePermissions={true}
				isCollapsible={false}
				Editor={GroupsEditor}
				columnsConfig={GroupsGridColumns}
				
				
				{...props}
			/>;
}