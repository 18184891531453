/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PermissionsEditor from '../Editor/PermissionsEditor.js';
import PermissionsGridColumns from './Columns/PermissionsGridColumns.js';

export default function PermissionsSideGridEditor(props) {
	return <SideGridEditor
				reference="PermissionsSideGridEditor"
				model="Permissions"
				usePermissions={true}
				isCollapsible={false}
				Editor={PermissionsEditor}
				columnsConfig={PermissionsGridColumns}
				
				
				{...props}
			/>;
}