/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import GroupsEditorWindow from '../../Window/GroupsEditorWindow.js';
import GroupsGridColumns from '../../Grid/Columns/GroupsGridColumns.js';

export default function GroupsGridEditorPanel(props) {
	return <GridPanel
				reference="GroupsGridEditorPanel"
				model="Groups"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={GroupsEditorWindow}
				columnsConfig={GroupsGridColumns}
				
				
				{...props}
			/>;
}