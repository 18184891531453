// import Joi from 'Joi';
import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Local = {
	
	name: 'Local',
	model: {

		idProperty: 'key',
		displayProperty: 'value',
		sorters: [
			{
				name: 'value',
				direction: 'ASC',
				fn: 'natsort',
			}
		],

		validator: yup.object().shape({
			key: yup.string()
					.nullable()
					.required(),
			value: yup.string()
					.nullable()
					.required(),
		}),

		properties: [
			{ name: 'key', },
			{ name: 'value', },
			{ name: 'isOneBuild', type: 'bool', },
			{ name: 'isJson', type: 'bool', },
			{ name: 'model', },
		],
		
	},

	repository: {
		type: 'local',
		isPaginated: false,
		isAutoLoad: true,
	},

};

export default Local;
