/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import UsersGridColumns from '../../Grid/Columns/UsersGridColumns.js';

export default function UsersGridPanel(props) {
	return <GridPanel
				reference="UsersGridPanel"
				model="Users"
				usePermissions={true}
				columnsConfig={UsersGridColumns}

				{...props}
			/>;
}