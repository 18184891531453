/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	logoutThunk,
} from '../models/Slices/AppSlice';

export default (props) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(logoutThunk());
	}, []);

	return null;
}
