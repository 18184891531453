/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import GroupsUsersEditor from '../Editor/GroupsUsersEditor.js';
import GroupsUsersGridColumns from './Columns/GroupsUsersGridColumns.js';

export default function GroupsUsersSideGridEditor(props) {
	return <SideGridEditor
				reference="GroupsUsersSideGridEditor"
				model="GroupsUsers"
				usePermissions={true}
				isCollapsible={false}
				Editor={GroupsUsersEditor}
				columnsConfig={GroupsUsersGridColumns}
				
				
				{...props}
			/>;
}