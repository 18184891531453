/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import GroupsUsersGridColumns from '../../Grid/Columns/GroupsUsersGridColumns.js';

export default function GroupsUsersGridPanel(props) {
	return <GridPanel
				reference="GroupsUsersGridPanel"
				model="GroupsUsers"
				usePermissions={true}
				columnsConfig={GroupsUsersGridColumns}

				{...props}
			/>;
}