/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import UsersSideGridEditorPanel from './UsersSideGridEditor.js';


export default function UsersFilteredSideGridEditorPanel(props) {
	return <UsersSideGridEditorPanel
				reference="UsersFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}